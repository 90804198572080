import React, { Component } from 'react';
import { LioWebRTC } from 'react-liowebrtc';
import { Document, Page, pdfjs } from 'react-pdf';
import './App.css';
import ChatBox from './ChatBox';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chatLog: [],
      options: {
        debug: true,
        dataOnly: true
      },
      numPages: null,
      pageNumber: 1,
    }
  }

  join = (webrtc) => {
    webrtc.joinRoom('my-p2p-app-demo');
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  handleCreatedPeer = (webrtc, peer) => {
    this.addChat(`Peer-${peer.id.substring(0, 5)} joined the room!`, ' ', true);
  }

  handlePeerData = (webrtc, type, payload, peer) => {
    switch(type) {
      case 'chat':
        this.addChat(`Peer-${peer.id.substring(0, 5)}`, payload);
        break;
      default:
        return;
    };
  }

  addChat = (name, message, alert = false) => {
    this.setState({ chatLog: this.state.chatLog.concat({
      name,
      message: `${message}`,
      timestamp: `${Date.now()}`,
      alert
    })});
  }

  generatePages = () => {
    const pages = [];
    for (let i=1; i<18; i+=1) {
      pages.push(<Page className="page" key={`page_${i}`} pageNumber={i} width={window.innerWidth <= 900 ? window.innerWidth : 900} />)
    }
    return (
      <div>
        {pages}
      </div>
    )
  }

  render() {
    const { chatLog } = this.state;
    return (
      <div className="App">
        <h1>Building a P2P Chat App with LioWebRTC</h1>
        <div className="desc">
          <p>Below is a working demo of the chat box component you will be creating with this instruction set. Once you're done playing with it, scroll down to view the instruction set.</p>
        </div>
        <LioWebRTC
            options={this.state.options}
            onReady={this.join}
            onCreatedPeer={this.handleCreatedPeer}
            onReceivedPeerData={this.handlePeerData}
          >
          <ChatBox
            chatLog={chatLog}
            onSend={(msg) => msg && this.addChat('Me', msg)}
          />
        </LioWebRTC>
        <div className="doc">
          <Document
            file="./proj5.pdf"
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            {this.generatePages()}
          </Document>
        </div>
      </div>
    );
  }
}

export default App;
